import React , { useState } from 'react';
import Project from '../../api/project'
import { Link } from "react-router-dom";
import { Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import Bg from '../../images/shapes/shape_title_under_line.svg'
import shape1 from '../../images/shapes/shape_line_2.svg'
import shape2 from '../../images/shapes/shape_line_3.svg'
import shape3 from '../../images/shapes/shape_line_4.svg'
import shape4 from '../../images/shapes/shape_space_3.svg'

import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';
// import TechnologySection from '../software-company-components/TechnologySection/TechnologySection';
// import Testimonial from '../Testimonial/Testimonial';
import WhyUs from '../../main-component/AboutUsPage/WhyUs';
import FaqSection from '../software-company-components/FaqSection/FaqSection';
import ContactSection from '../software-company-components/ContactSection/index';

const ProjectSection = (props) => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const [open, setOpen] = useState('1');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };


    return (
        <section className="process_technology_review_section bg-light section_decoration">
        <div className="container">
            <div className="row align-items-center justify-content-lg-between">
                <div className="col-lg-6">
                    <div className="heading_block">
                        <div className="heading_focus_text has_underline d-inline-flex" style={{ backgroundImage: `url(${Bg})` }}>
                            Working Process
                        </div>
                        <h2 className="heading_text mb-0">
                        Start Your<mark> Learning and Career Journey With Us</mark>
                        </h2>
                    </div>
                    <Accordion open={open} toggle={toggle} className="accordion" id="service_process_faq">
                        <AccordionItem className="accordion-item">
                            <AccordionHeader targetId="1">
                                01. Online Registration
                            </AccordionHeader>
                            <AccordionBody accordionId="1" className='acc_body'>
                                <p className="m-0">
                                    Visit our website and fill out the online registration form.

                                </p>
                                <p>
                                Provide your basic information and express your interest in joining XNovaQ's Employee Enablement Program.
                                </p>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem className="accordion-item">
                            <AccordionHeader targetId="2">
                                02. Schedule an Appointment
                            </AccordionHeader>
                            <AccordionBody accordionId="2" className='acc_body'>
                                <p className="m-0">
                                Our Business Manager will contact you to schedule a convenient time for an in-person meeting at our Coimbatore office.
                                </p>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem className="accordion-item">
                            <AccordionHeader targetId="3">
                                03. Initial Assessment
                            </AccordionHeader>
                            <AccordionBody accordionId="3" className='acc_body'>
                                <p className="m-0">
                                Attend an initial assessment session to evaluate your suitability for the program.
                                </p>
                                <p>
                                This assessment will assess your technical aptitude, communication skills, and overall fit for the program.
                                </p>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem className="accordion-item">
                            <AccordionHeader targetId="4">
                                04. Enrollment and Course Selection
                            </AccordionHeader>
                            <AccordionBody accordionId="4" className='acc_body'>
                                <p className="m-0">
                                Based on the assessment results, you will be guided through the enrollment process.
                                </p>
                                <p>
                                Choose the course that aligns best with your career goals and interests.
                                </p>
                                <p>
                                Explore flexible payment options, including EMIs, to make your investment affordable.
                                </p>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem className="accordion-item">
                            <AccordionHeader targetId="5">
                                05. Kickstart Your Learning Journey
                            </AccordionHeader>
                            <AccordionBody accordionId="5" className='acc_body'>
                                <p className="m-0">
                                Begin your journey towards becoming a skilled IT professional.
                                </p>
                                <p>
                                Learn from experienced instructors, work on real-world projects, and gain valuable hands-on experience.
                                </p>
                                <p>
                                Enjoy the benefits of a competitive salary and job placement assistance from day on
                                </p>
                            </AccordionBody>
                        </AccordionItem>
                    </Accordion>
                </div>
                <div className="col-lg-5">
                    <Accordion open={open} toggle={toggle} className="accordion" id="service_process_faq">
                        <ul className="content_layer_group unordered_list_block text-center">
                            <li><AccordionHeader targetId="1"><span>Online Registration</span></AccordionHeader></li>
                            <li><AccordionHeader targetId="2"><span>Schedule an Appointment</span></AccordionHeader></li>
                            <li><AccordionHeader targetId="3"><span>Initial Assessment</span></AccordionHeader></li>
                            <li><AccordionHeader targetId="4"><span>Enrollment and Course Selection</span></AccordionHeader></li>
                            <li><AccordionHeader targetId="5"><span>Kickstart Your Learning Journey</span></AccordionHeader></li>
                        </ul>
                    </Accordion>
                </div>
            </div>
            {/* <TechnologySection/> */}
            {/* <Testimonial/> */}
        </div>

        <div className="decoration_item shape_image_1">
            <img src={shape1} alt="Techco Shape" />
        </div>
        <div className="decoration_item shape_image_2">
            <img src={shape2} alt="Techco Shape" />
        </div>
        <div className="decoration_item shape_image_3">
            <img src={shape3} alt="Techco Shape" />
        </div>
        <div className="decoration_item shape_image_4">
            <img src={shape4} alt="Techco Shape" />
        </div>
       <WhyUs/>
       <FaqSection/>
       <ContactSection/>
    </section>
 
        // <section className="portfolio_section xb-hidden section_space pb-0">
        //     <div className="container">
        //         <div className="heading_block">
        //             <div className="row align-items-end">
        //                 <div className="col-lg-7">
        //                     <div className="heading_focus_text">
        //                         <span className="badge bg-secondary text-white">Crafting</span>
        //                         Success With 😍 Project
        //                     </div>
        //                     <h2 className="heading_text">
        //                         Our Recent Best Works
        //                     </h2>
        //                     <p className="heading_description mb-0">
        //                         Our recent projects highlight our expertise in delivering tailored solutions that meet the unique needs and objectives of our clients,custom software.
        //                     </p>
        //                 </div>
        //                 <div className="col-lg-5 d-none d-lg-flex justify-content-end">
        //                     <Link onClick={ClickHandler} to={'/portfolio'} className="btn btn-primary">
        //                         <span className="btn_label" data-text="All Works">All Works</span>
        //                         <span className="btn_icon">
        //                             <i className="fa-solid fa-arrow-up-right"></i>
        //                         </span>
        //                     </Link>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        //     <div className="portfolio_carousel">
        //         <Swiper
        //             // install Swiper modules
        //             modules={[Pagination, A11y]}
        //             slidesPerView={1}
        //             loop={true}
        //             spaceBetween= {30}
        //             allowTouchMove= {true}
        //             centeredSlides= {true}
        //             pagination={{ clickable: true }}
        //             speed={400}
        //             parallax={true}
        //             breakpoints={{
        //                 1025: {
        //                     slidesPerView: 2,
        //                 },
        //             }}
        //         >

        //             {Project.slice(0, 5).map((project, prj) => (
        //                 <SwiperSlide key={prj}>
        //                     <div className="portfolio_block" >
        //                         <div className="portfolio_image">
        //                             <Link onClick={ClickHandler} className="portfolio_image_wrap bg-light" to={`/portfolio_details/${project.slug}`}>
        //                                 <img src={project.pImg} alt="Mobile App Design" />
        //                             </Link>
        //                         </div>
        //                         <div className="portfolio_content">
        //                             <h3 className="portfolio_title">
        //                                 <Link onClick={ClickHandler} to={`/portfolio_details/${project.slug}`}>
        //                                     {project.title}
        //                                 </Link>
        //                             </h3>
        //                             <ul className="category_list unordered_list">
        //                                 <li><Link onClick={ClickHandler} to={`/portfolio_details/${project.slug}`}>{project.sub}</Link></li>
        //                             </ul>
        //                             <Link onClick={ClickHandler} className="btn btn-outline-light" to={`/portfolio_details/${project.slug}`}>
        //                                 <span className="btn_label" data-text="Explore">Explore</span>
        //                                 <span className="btn_icon">
        //                                     <i className="fa-solid fa-arrow-up-right"></i>
        //                                 </span>
        //                             </Link>
        //                         </div>
        //                     </div>
        //                 </SwiperSlide>
        //             ))}
        //         </Swiper>
        //     </div>
        //     <div className="container text-center d-block d-lg-none">
        //         <div className="btns_group pb-0">
        //             <Link onClick={ClickHandler} className="btn btn-primary" to="/pricing">
        //                 <span className="btn_label" data-text="All Works">All Works</span>
        //                 <span className="btn_icon">
        //                     <i className="fa-solid fa-arrow-up-right"></i>
        //                 </span>
        //             </Link>
        //         </div>
        //     </div>
        // </section>
    );
}

export default ProjectSection;